const initialState = {
    interest: localStorage.getItem('interest') ?? null,
  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'showInterest':
        return {
          ...state,
          interest: action.value,
        };
      
      default:
        return state;
    }
  };
  export default reducer;
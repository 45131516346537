import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import { GetLanguageById } from 'src/api';
import { useNavigate, useParams } from 'react-router-dom';

const ShowLanguage = () => {
  const [language, setLanguage] = useState({
    value: '',
    languageCode: '',
  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLanguageDetails = async () => {
      try {
        const response = await GetLanguageById(id);
        if (response.status === 200) {
          const languageData = response.data.language;
          setLanguage(languageData);
        }
      } catch (error) {
        console.error('Error fetching language details:', error);
      }
    };

    fetchLanguageDetails();
  }, [id]);

  const handleBack = () => {
    navigate('/super_admin/dashboard/language');
  };

  return (
    <Container style={{ margin: '20px 0' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Name: {language.value}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Language Code: {language.languageCode}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleBack}>
            Back to Language List
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShowLanguage;

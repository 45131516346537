const initialState = {
    interest: localStorage.getItem('interest') ?? null,
    seed:1
  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'setInterest':
        return {
          ...state,
          interest: action.value,
        };
      case 'showInterest':
        return {
          ...state,
          interest: action.value,
        };
      case 'resetInterest':
        localStorage.removeItem('interest')
        return {
          ...state,
          interest: null,
          seed:Math.random()
        };
      default:
        return state;
    }
  };
  export default reducer;
const initialState = {
    show: true,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'hide':
        return {
          ...state,
          show: false,
        };
      case 'show':
        return {
          ...state,
          show: true,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  
import React,{useState} from 'react';

import  { Box, Container, Grid, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon  from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useDispatch, useSelector } from 'react-redux';
import XIcon from '@mui/icons-material/X';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'
import Actions from 'src/actions';
import InstagramIcon from '@mui/icons-material/Instagram';
import Typography from 'src/theme/typography';
import { useTranslation } from 'react-i18next';




const useStyles = makeStyles((theme) => ({
  footer: {
    
    padding:'30px',
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: '#0f2136',
    display:'block'
  },
  text:{
    
    color: '#fff',
    // fontSize: '15px',
    textDecoration: 'none',
    padding:'10px 0',
    display:'block'
  },
  title:{
    color: '#5ea6da',
    display: 'block',
    // fontSize: '26px',
    margin: '30px 0 15px',
    display:'block',
    display:'block'
    
  },
  icon:{
    borderRadius:' 5px',
    lineHeight: '35px',
    height: '35px',
    width: '35px',
    display:'block',
    background:'white',
    color:'black',
    margin:'0px 7px'
    
  },
  dropdown:{
    backgroundColor: '#0f2136',
    
  },
  copyrightText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    
  },
}
));


const Footer = () => {
  const classes = useStyles();
  const key = useSelector((state) => state.meeting.key);
  const { t } = useTranslation();
  
  
  const [selectedLanguage, setSelectedLanguage] = useState('en'); 
  const dispatch=useDispatch();
  const handleLanguageChange = (event) => {
    let launguage=event.target.value
    setSelectedLanguage(event.target.value);
    dispatch({ type: 'SET_LANGUAGE', payload: launguage });

  };
  const ChangePage =()=>{
    dispatch(Actions.Media.leaveMeeting())
    if(key){
      dispatch({ type: 'reset' });
    }
  }
  

  return (
    <>
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={3} color={'white'}>
          <Grid item xs={12} sm={6} md={3}>
            <Link to={'/'} className={classes.title} variant="h3">
              {t('learn')}
            </Link>
            <Link to={'/'} className={classes.text} variant="body2">
              {t('home')}
            </Link>
            <Link to={`/about`} className={classes.text} variant="body2">
              {t('aboutUs')}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link to={'/'} className={classes.title} variant="h3">
              {t('followUs')}
            </Link>
            <Link onClick={ChangePage} to={'/privacy'} className={classes.text} variant="body2">
              {t('privacyPolicy')}
            </Link>
            <Link to={'/term'} onClick={ChangePage} className={classes.text} variant="body2">
              {t('termsAndConditions')}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link to={'/'} className={classes.title} variant="h3">
              {t('company')}
            </Link>
            <Link to={'/'} className={classes.text} variant="h6">
              {t('copyright')}
            </Link>
            <Link to={'/contact'} className={classes.text} variant="body2">
              {t('contactUs')}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link to={'/'} className={classes.title} variant="h3">
              {t('socials')}
            </Link>
            <Link to="https://youtube.com/@Official_rizztv?si=qAY7J01qYX6OOoFW" target="_blank">
              <YouTubeIcon className={classes.icon} />
            </Link>
            <Link to="https://www.instagram.com/rizztv_official?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" target="_blank">
              <InstagramIcon className={classes.icon} />
            </Link>
            <Link to="https://x.com/rizztv_official/status/1748906484661157930?s=46&t=Har8HUw7CBS5c5-qIitg3Q" target="_blank">
              <XIcon className={classes.icon} />
            </Link>
            <Link to="https://www.facebook.com/profile.php?id=61555716174390&mibextid=cr9u03" target="_blank">
              <FacebookIcon className={classes.icon} />
            </Link>

            <Link to={'/'} style={{ marginTop: '10px' }} className={classes.text} variant="body2">
              <Select
                sx={{
                  color: 'white',
                }}
                value={selectedLanguage}
                onChange={handleLanguageChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select Language' }}
              >
                <MenuItem className={classes.dropdown} value="" disabled>
                  {t('selectLanguage')}
                </MenuItem>
                <MenuItem className={classes.dropdown} value="en">
                  {t('english')}
                </MenuItem>
                <MenuItem className={classes.dropdown} value="hi">
                  {t('hindi')}
                </MenuItem>
                <MenuItem className={classes.dropdown} value="ar">
                  {t('arabic')}
                </MenuItem>
              </Select>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
    <Divider />
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Box className={classes.copyrightText}>
          <Box>
            <Link className={classes.text} variant="body2">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
          <Link variant="body2" color="textSecondary">
            {t('allRightsReserved', { year: new Date().getFullYear(), rizzTv: t('rizzTv') })}
          </Link>
        </Box>
      </Container>
    </footer>
  </>
    
  );
};

export default Footer;

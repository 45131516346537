
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'
import Cookies from 'js-cookie';
import { useState,useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const pages = ['language', , 'block','page'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [button, setButton] = useState(false)
  const dispatch=useDispatch();
  const logoutRef=useRef();
  const isLogin=useSelector((state)=>state.user.token);

  const navigate=useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout=()=>{
    sessionStorage.removeItem('token');
dispatch({type:"logout"})
    setButton(false)
    navigate('/super_admin/login')
  }


  useEffect(() => {


    CheckUserIsLogin();
 
    
  }, [isLogin]);
  
  
  function CheckUserIsLogin(){

    if (isLogin===0) {
      setButton(false)
    } else {
      setButton(true)
      
    }
  }

  return (
    <AppBar position="static" style={{ background: '#00a7dd' }}>
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Link to='/'>
          <img src={logo} height={50} alt="Logo" />
        </Link>
  
        {/* Mobile Menu Button */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleOpenNavMenu}
          >
            <MenuIcon />
          </IconButton>
        </Box>
  
        {/* Navigation Menu */}
        <Box sx={{ flexGrow: 1,  display: { xs: 'none', md: 'flex' } }}>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {button && pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography   onClick={() => { navigate(`/super_admin/dashboard/${page}`) }}textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box> 
  
        {/* Navigation Buttons (for larger screens) */}
        { <Box sx={{ flexGrow: 1,  display: { xs: 'none', md: 'flex'  } }}>
          {button && pages.map((page) => (
            <Button
              key={page}
              onClick={() => { navigate(`/super_admin/dashboard/${page}`) }}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page}
            </Button>
          ))}
        </Box>}
  
        {/* Logout Button */}
        {button && (
          <Box ml="auto">
            <Button variant="contained" color="primary" className='text-white' ref={logoutRef} onClick={logout}>Logout</Button>
          </Box>
        )}
      </Toolbar>
    </Container>
  </AppBar>
  );
}
export default ResponsiveAppBar;
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'src/actions';
import getLocalAudio from 'src/actions/media/getLocalAudio';
import getLocalVideo from 'src/actions/media/getLocalVideo';
import releaseLocalAudio from 'src/actions/media/releaseLocalAudio';
import releaseLocalVideo from 'src/actions/media/releaseLocalVideo';
import store from 'src/store';
import Utils from 'src/utils';
import OutlinedCard from 'src/views/Interest/Card';


export default function Interest() {
  const toggle = useSelector((state) => state.dropdown.show);
  const available = useSelector((state) => state.media);
  const active = useSelector((state) => !!state.media.local.video);
  const dispatch = useDispatch();

  const displayStyle = toggle ? 'flex' : 'none';


  return (
    <div>
      <Box
        flexDirection="column"
        height="100%"
        justifyContent="center"
        display={displayStyle}
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Container sx={{ maxWidth: '320px' }}>
          <Box display="flex" flexDirection="column" alignItems="center" p={1}>
            <OutlinedCard />
          </Box>
        </Container>
      </Box>
    </div>
  );
}

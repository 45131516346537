import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import image from '../assets/chat.jpg'
import image1 from '../assets/chat1.jpg'
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const theme = useTheme();
  const {t}=useTranslation();
  return (
    <>
      <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px 0' }}>
        <Grid item xs={12} md={4} style={{ marginTop: '20px' }}>
          <Paper style={{ padding: '20px 10px', background: 'white', border: 0 }}>
            <Typography variant="h3" component="h3" style={{
              color: '#009dd0',
              display: 'block',
              padding: '10px 0',
              fontWeight: '600',
            }}>
          {t('freeRandomVideoChat')}
            </Typography>
            <Typography style={{
              background: 'white', height: '200px', border: 0
            }}>
              {t('rizzTvText')}                
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
          <img
            style={{ width: '90%', [theme.breakpoints.down('sm')]: { width: '100%' } }}
            src={image}
            alt="Profile"
          />
        </Grid>

      

        <Grid container xs={12} md={10} style={{ marginTop: '20px', }}>
          <Paper style={{ padding: '20px 10px', background: 'white', border: 0, boxShadow: '0' }}>
            <Typography variant="h3" component="h3" style={{
              color: '#009dd0',
              display: 'block',
              padding: '10px 0',
              fontWeight: '600',
            }}>
  {t('welcomeTitle')} 
            </Typography>
            <Typography style={{
              background: 'white', border: 0
            }}>
                  {t('welcomeMessage')}           
                </Typography>
<Typography variant='h4' style={{marginTop:'4px'}}>
Contact Us:<a href="mailto:officialrizztv@gmail.com">officialrizztv@gmail.com</a>


</Typography>
          </Paper>
        </Grid>
      </Grid>


    </>

  );
}

import { Typography } from '@mui/material';
import React from 'react';

export default function Block() {
  const contactEmail = 'officialrizztv@gmail.com';

  return (
    <div>
      <Typography variant='h2' sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>Blocked User Page</Typography>
      <Typography>
      You are temporarily restricted from accessing this content due to a violation of our terms and conditions. If you have any concerns or need further assistance, please feel free to reach out to us at:        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
      </Typography>
    </div>
  );
}

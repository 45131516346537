import {
  Box, Button, Card, Container, Typography, useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { OpenInNew, Shuffle } from '@mui/icons-material';
import { OpenInNew } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
// import randomWords from 'random-words';
// import { useEffect } from 'react';
import Common from '../../common';
import config from '../../config';
// import MeetingKeyInput from './MeetingKeyInput';
// import GoToButton from './GoToButton';
// import info from '../../version.json';
// import logo from './logo.png';
// import Utils from '../../utils';
// import Navbar from 'src/component/Navbar';
import Interest from 'src/component/Interest';
import React, { useEffect, useState } from 'react';
import Loader from 'src/component/Loader';
import Meeting from 'src/component/Meeting';
import Chat from 'src/component/Chat';
import Join from 'src/component/Join';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';
import Actions from 'src/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flex: 1,
  },
  brand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 60,
  },
  appTitle: {
    fontFamily: 'KaushanScript',
    color: theme.palette.primary.deep,
  },
}));

// function HomeManager() {
//   const dispatch = useDispatch();
//   const key = useSelector((state) => state.meeting.key);
//   const [token, setToken] = useState(false)
//   const [interest, setInterest] = useState('')



//   return null;
// }

function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const key = useSelector((state) => state.meeting.key);
  const useinterest = useSelector((state) => state.interest);
  const [token, setToken] = useState(false)
  const [interest, setInterest] = useState('')
  const dispatch = useDispatch()
  const seed = useSelector((state) => state.meeting.seed);
  const home = useSelector((state) => state.interest.seed);


  

  useEffect(() => {
    const fetchIPAddress = async () => {

   
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        dispatch({ type: 'ipaddress', ip: data.ip });
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
      let check = localStorage.getItem('interest');
      if (check) {
        let parsedData = JSON.parse(check);
       

        setInterest(parsedData)
        setToken(true)
      } 
    };

    fetchIPAddress();

  return ()=>{
    setToken(false)

  }
    // console.log(useinterest)
  }, [useinterest, home])



  return (
    <Common.Page className={classes.root} title={`Home | ${config.appTitle}`}>



      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Modal />
        {!token ? <Interest /> : <Join key={seed} />}



        {/* <Container maxWidth="xs" >
          <Card>
            <Box display="flex" flexDirection="column" alignItems="center" p={3}>
              <Box className={classes.brand}>
                <img className={classes.logo} src={logo} alt={`${config.appTitle} Logo`} />
                <Box mb={1}>
                  <Typography
                    variant="h3"
                    component="p"
                    textAlign="center"
                    className={classes.appTitle}
                    sx={{ fontFamily: 'KaushanScript', fontSize: 32 }}
                  >
                    {config.appTitle}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1}>
                <Typography
                  variant="h6"
                  component="p"
                  style={{ color: theme.palette.primary.deep, textAlign: 'center' }}
                >
                  Enterprise Meetings Platform
                </Typography>
              </Box>
              {config.demo && (
                <Box width="60%" display="flex" pt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    href="https://codecanyon.net/user/trigonotech/portfolio"
                    target="_blank"
                    size="small"
                    endIcon={<OpenInNew />}
                  >
                    Buy on CodeCanyon
                  </Button>
                </Box>
              )}
              <MeetingKeyInput />
              <Box mt={1}>
                <Typography
                  variant="h6"
                  component="p"
                  style={{ color: theme.palette.primary.deep, textAlign: 'center' }}
                >
                  - or -
                </Typography>
              </Box>
              <Box width="60%" display="flex" pt={1} pb={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => dispatch({ type: 'meeting-key-random' })}
                  size="small"
                  endIcon={<Shuffle />}
                >
                  Generate Random
                </Button>
              </Box>
              {config.demo && (
                <Box mt={3}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ color: theme.palette.primary.deep, textAlign: 'center' }}
                  >
                    Warning: this is a demo. Please do not hold your business meetings on this
                    server.
                  </Typography>
                </Box>
              )}
              <GoToButton />
            </Box>
          </Card>
        </Container> */}
        {/* <Box mt={1}>
          <Typography
            variant="caption"
            component="p"
            style={{ color: theme.palette.text.secondary, textAlign: 'center' }}
          >
            2022 &copy;
            {' v'}
            {info.version}
            {' ('}
            {info.build}
            )
          </Typography>
        </Box> */}
      </Box>



    </Common.Page>
  );
}

function Modal() {
  let modalOpened = false;
  // const [modalOpened, setModalOpened] = useState(false)
  const dispatch = useDispatch()
  const navigate=useNavigate();

  if (localStorage.getItem('termsAccepted')) {
    modalOpened = false;
    dispatch({ type: 'show' });

  } else {
    modalOpened = true;
    dispatch({ type: 'hide' });

  }
  const [open, setOpen] = React.useState(modalOpened);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (agree) => {
    if (agree) {

      localStorage.setItem('termsAccepted', 1)
      // navigate('/')
    }
    if(agree==false){

      // navigate('/term/condition/reject')

    }
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog
      style={{background:'white'}}
        open={open}
      
        // onClick={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{background:'white'}} id="alert-dialog-title">
         <Typography variant='h3'> {"Welcome to Rizz TV"} </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color:'black' ,background:'white'}} id="alert-dialog-description">
            <Typography>
            To continue, please confirm that you are 18 years old or above.            
            </Typography>
            By entering, you agree to our Terms and Conditions and Privacy Policy. Take a moment to review these documents for a delightful and secure experience.

          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex',justifyContent:'space-between', flexDirection:'column'}}>
          
          <Button style={{ height: '52px', fontSize:'12px', background: '#00a7dd', color: 'white',marginBottom:'10px'  ,width:'263px'}} onClick={() => handleClose(true)} autoFocus>
          Yes, I'm 18 or above and agree
          </Button>



<Typography>

          <Link style={{margin:'0px 10px'}} to='/term/condition' target='_blank' >
          

            Tell me more about the terms
          
          </Link>
          |
          <Link style={{margin:'0px 10px'}} to='/privacy' target='_blank' >
         
            Show me the privacy policy
           
          </Link>
          </Typography>
       
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}


export default Home;

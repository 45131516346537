import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { GetAllInterest, DeleteInterest } from 'src/api';
import { Link, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

const MyTable = () => {
  const [interest, setInterest] = useState([]);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    async function Interest() {
      let admin =sessionStorage.getItem('token');
      if (!admin) {
        navigate('/super_admin/login')
return 
      }
      let response = await GetAllInterest('en');
      if (response.status === 200) {
        setInterest(response.data.interest);
      }
    };
    Interest()
  }, [refresh]);

  const Handledelete = async (id) => {
    const response = await DeleteInterest(id)
    if (response.status === 200) {
      setRefresh(!refresh)
    }
  }

  return (
    <>
      <TableContainer sx={{ textAlign: 'right' }}>
        <Button className='text-white' sx={{ margin: '10px 0' }} variant="contained" color="primary" onClick={() => navigate('/super_admin/dashboard/interest/create')} >Create</Button>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Language Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interest.map((e, i) => (
              <TableRow key={e._id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{e.languageCode}</TableCell>
                <TableCell>{e.value}</TableCell>
                <TableCell>
                  <Link to={`/super_admin/dashboard/interest/edit/${e._id}`}>Edit</Link>
                </TableCell>
                <TableCell>
                  <Link to={`/super_admin/dashboard/interest/view/${e._id}`}>View</Link>
                </TableCell>
                <TableCell>
                  <Button onClick={() => { Handledelete(e._id) }} >Delete</Button>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>


      </TableContainer>

    </>
  );
};

export default MyTable;

import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, MenuItem, responsiveFontSizes } from '@mui/material';
import { CreateInterest, GetAllLaunguage } from 'src/api';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const InterestForm = () => {
  const [interests, setInterests] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
const navigate=useNavigate();
  const handleChange = (languageCode, property) => (event) => {
    const existingInterest = interests.find((i) => i.languageCode === languageCode) || {};
    const updatedInterests = interests.filter((i) => i.languageCode !== languageCode);
    updatedInterests.push({
      ...existingInterest,
      languageCode,
      [property]: event.target.value,
    });
    setInterests(updatedInterests);
  };

  const handleLanguageChange = (languageCode) => {
    // Save data for the previous language before changing
    if (selectedLanguage) {
      const existingInterest = interests.find((i) => i.languageCode === selectedLanguage) || {};
      const updatedInterests = interests.filter((i) => i.languageCode !== selectedLanguage);
      updatedInterests.push(existingInterest);
      setInterests(updatedInterests);
    }

    setSelectedLanguage(languageCode);
  };
  const defaultTheme = createTheme();

  useEffect(() => {
    (async function fetchLanguages() {
      try {
        const response = await GetAllLaunguage(); 
        if (response.status === 200) {
          setLanguages(response.data.languages);
          if (response.data.languages.length > 0) {
            setSelectedLanguage(response.data.languages[0].languageCode);
          }
        }
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    })();
  }, []); 

  const handleSubmit = async(event) => {
    event.preventDefault();

    const nonEmptyInterests = interests.filter((interest) => Object.keys(interest).length > 1);

    const data={
        interest: nonEmptyInterests
    }

   let response=await CreateInterest(data);


   if(response.status==201){
    navigate('/super_admin/dashboard/interest')
   }

  };

  return (
    <ThemeProvider theme={defaultTheme} >
    <Container style={{margin:'20px 0'}}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            select
            label="Language"
            variant="outlined"
            fullWidth
            name="languageCode"
            value={selectedLanguage}
            onChange={(event) => handleLanguageChange(event.target.value)}
          >
            {languages.map((lang) => (
              <MenuItem key={lang.languageCode} value={lang.languageCode}>
                {lang.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={9}>
          <form onSubmit={handleSubmit}>
            {languages.map((language) => (
              <div key={language.languageCode} style={{ display: selectedLanguage === language.languageCode ? 'block' : 'none' }}>
                <TextField
                  label="Value"
                  variant="outlined"
                  fullWidth
                  name={`value_${language.languageCode}`}
                  value={interests.find((i) => i.languageCode === language.languageCode)?.value || ''}
                  onChange={handleChange(language.languageCode, 'value')}
                />
              </div>
            ))}

            <Button type="submit" variant="contained" color="primary">
              Create Interest
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
    </ThemeProvider>
  );
};

export default InterestForm;

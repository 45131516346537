import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Grid, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Actions from 'src/actions';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/logo.png'
import ShareIcon from '@mui/icons-material/Share';
import { RWebShare } from "react-web-share";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { GetAllLaunguage } from 'src/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';




const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [selectedLanguage, setSelectedLanguage] = React.useState('en');

  const selectedLanguage=useSelector((e)=>e.language.selectedLanguage);

  const [language, setLanguage] = useState([])
  const dispatch = useDispatch();
  const Interest=useSelector((state)=>state.interest.interest)
  const { t, i18n } = useTranslation();


  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const handleLanguageChange = (event) => {
 

    dispatch({ type: 'SET_LANGUAGE', payload: event.target.value });
    i18n.changeLanguage(event.target.value);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const DeleteInterest = () => {
    dispatch(Actions.Media.leaveMeeting());
    dispatch({ type: 'showInterest',value: Interest});
    dispatch({ type: 'reset' });
    dispatch({ type: 'resetInterest' });
    dispatch({ type: 'show' });
  };
  useEffect(() => {
    async function Languages() {
      let response = await GetAllLaunguage();

      if (response.status == 200) {
        setLanguage(response.data.languages)
      }

    }
    Languages()
  }, [])


  return (
    <AppBar position="static" sx={{ width: '100%', background: '#F4F4F4' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Grid xs={12} md={8}>
            <Link to={'/'}>
              <img src={logo} alt="Logo" />
            </Link>
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: 'end' } }}>
            <IconButton size="large" aria-label="menu" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={DeleteInterest}>
                <Button style={{ height: '52px', background: '#00a7dd', color: 'white' }} >Change Interest</Button>
              </MenuItem>
              <MenuItem>
                <Select
                  sx={{
                    backgroundColor: '#00a7dd',
                    color: 'white',
                  }}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Language' }}
                >
                  <MenuItem value="" disabled>
                    Select Language
                  </MenuItem>
                  {
                    language.map((e) => {
                      return (  
                          <MenuItem value={e.languageCode}>{e.value}</MenuItem>
                      )
                    })
                  }

                  {/* <MenuItem className="menu_item_style" value="en">
                    English
                  </MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem> */}
                </Select>
              </MenuItem>
            </Menu>
          </Box>
          <Box style={{ marginLeft: 'auto' }}>
            <RWebShare
              data={{
                text: "Explore the world of entertainment with the Rizz TV",
             
                url: window.location.url,
                title: "Rizz TV",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <ShareIcon style={{ marginLeft: 'auto', cursor: 'pointer' }} />
            </RWebShare>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {/* <Button onClick={DeleteInterest} style={{ height: '52px', margin: '10px', background: '#00a7dd', color: 'white' }}>
          {t('changeInterest')}
            </Button> */}
            <Select
              sx={{
                backgroundColor: '#00a7dd',
                color: 'white',
              }}
              value={selectedLanguage}
              onChange={handleLanguageChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Select Language' }}
            >
               <MenuItem value="" disabled>
                    Select Language
                  </MenuItem>
                  {
                    language.map((e) => {
                      return (
                          <MenuItem value={e.languageCode}>{e.value}</MenuItem>
                      )
                    })
                  }
            </Select>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { GetAllLaunguage ,DeleteLanguage} from 'src/api';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const MyTable = () => {
  const [language, setLanguage] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const navigate=useNavigate();

  useEffect(() => {



    async function Language() {
      let adminToken =sessionStorage.getItem('token');

      if(!adminToken){
navigate('/super_admin/login')
return 
      }
      let response = await GetAllLaunguage();
      if (response.status === 200) {
        setLanguage(response.data.languages);

      }
    };

    Language()
  }, [refresh]);


  const Handledelete=async(id)=>{
    const response= await DeleteLanguage(id)
    if(response.status===200){
    setRefresh(!refresh)
    }
      }

  return (
    <>
    <TableContainer style={{textAlign:'right'}}>
      <Button sx={{margin:'10px 0'}} className='text-white' variant="contained" color="primary"  onClick={()=> navigate('/super_admin/dashboard/language/create')} >Create</Button>
      <Table>

        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Language Code</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>View</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {language.map((e ,i ) => (
            <TableRow key={e._id}>
              <TableCell>{i+1}</TableCell>
              <TableCell>{e.languageCode}</TableCell>
              <TableCell>{e.value}</TableCell>
              <TableCell>
                <Link to={`/super_admin/dashboard/language/edit/${e._id}`}>Edit</Link>
              </TableCell>
              <TableCell>
                <Link to={`/super_admin/dashboard/language/view/${e._id}`}>View</Link>
              </TableCell>
              <TableCell>
                <Button onClick={()=>{ Handledelete(e._id) }} >Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
    </TableContainer>

    </>
  );
};

export default MyTable;

const initialState = {
    video:true,
    audio:true,

  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'setvideo':
        return {
          ...state,
          video: false,
        };
      case 'setaudio':
        return {
          ...state,
          audio: false,
        };

      default:
        return state;
    }
  };
  
  export default reducer;
  
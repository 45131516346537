import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, MenuItem, responsiveFontSizes } from '@mui/material';
import { CreateLaunguage } from 'src/api';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const InterestForm = () => {
  const [languages, setLanguages] = useState({
    value:'',
    languageCode:''

  });

  
const defaultTheme = createTheme();

const handleChange = (e) => {
  setLanguages({
    ...languages,
    [e.target.name]: e.target.value,
  });
};
const  navigate=useNavigate();

  const handleSubmit = async(event) => {
    event.preventDefault();

   let response=await CreateLaunguage(languages);
  if(response.status==201){
    navigate('/super_admin/dashboard/language')
  }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ margin: '20px 0' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Language Code"
                variant="outlined"
                fullWidth
                name="languageCode"
                value={languages.languageCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="value"
                value={languages.value}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Create Language
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </ThemeProvider>
  );
};


export default InterestForm;

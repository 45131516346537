import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { GetAllPage, DeletePage } from 'src/api';
import { Link, useNavigate } from 'react-router-dom';

const MyTable = () => {
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function fetchPages() {
      let admin = sessionStorage.getItem('token');
      if (!admin) {
        navigate('/super_admin/login');
        return;
      }
      try {
        const response = await GetAllPage();
        if (response.status === 200) {
          setPages(response.data.page);
        }
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    }
    fetchPages();
  }, [refresh]);

  const handleDelete = async (id) => {
    try {
      const response = await DeletePage(id);
      if (response.status === 200) {
        setRefresh(!refresh);
      }
    } catch (error) {
      console.error('Error deleting page:', error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ textAlign: 'right' }}>
        <Button sx={{ margin: '10px 0' }} variant="contained" color="primary" onClick={() => navigate('/super_admin/dashboard/page/create')}>
          Create
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Language Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.map((page, index) => (
              <TableRow key={page._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{page.languageCode}</TableCell>
                <TableCell>{page.title}</TableCell>
                <TableCell>
                  <Link to={`/super_admin/dashboard/page/edit/${page._id}`}>Edit</Link>
                </TableCell>
                <TableCell>
                  <Link to={`/super_admin/dashboard/page/view/${page._id}`}>View</Link>
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleDelete(page._id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyTable;

import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, MenuItem } from '@mui/material';
import { CreatePage, GetAllLaunguage } from 'src/api';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';

const InterestForm = () => {
  const [pages, setPages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    (async function fetchLanguages() {
      try {
        const response = await GetAllLaunguage();
        if (response.status === 200) {
          setLanguages(response.data.languages);
          if (response.data.languages.length > 0) {
            setSelectedLanguage(response.data.languages[0].languageCode);
          }
        }
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    })();
  }, []);

  const handleChange = (languageCode, property) => (event) => {
    const existingInterest = pages.find((i) => i.languageCode === languageCode) || {};
    const updatedInterests = pages.filter((i) => i.languageCode !== languageCode);
    updatedInterests.push({
      ...existingInterest,
      languageCode,
      [property]: event.target.value,
    });
    setPages(updatedInterests);
  };

  const handleLanguageChange = (languageCode) => {
    if (selectedLanguage) {
      const existingInterest = pages.find((i) => i.languageCode === selectedLanguage) || {};
      const updatedInterests = pages.filter((i) => i.languageCode !== selectedLanguage);
      updatedInterests.push(existingInterest);
      setPages(updatedInterests);
    }

    setSelectedLanguage(languageCode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const nonEmptyInterests = pages.filter((page) => Object.keys(page).length > 1);

    const data = {
      page: nonEmptyInterests,
    };

    let response = await CreatePage(data);

    if (response.status === 201) {
      navigate('/super_admin/dashboard/page');
    }

  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container style={{ margin: '20px 0' }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              select
              label="Language"
              variant="outlined"
              fullWidth
              name="languageCode"
              value={selectedLanguage}
              onChange={(event) => handleLanguageChange(event.target.value)}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.languageCode} value={lang.languageCode}>
                  {lang.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={9}>
            <form onSubmit={handleSubmit}>
              {languages.map((language) => (
                <div key={language.languageCode} style={{ display: selectedLanguage === language.languageCode ? 'block' : 'none' }}>
                  <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    name={`title_${language.languageCode}`}
                    value={pages.find((i) => i.languageCode === language.languageCode)?.title || ''}
                    onChange={handleChange(language.languageCode, 'title')}
                  />
                  <CKEditor
                    editor={ClassicEditor}
                    data={pages.find((i) => i.languageCode === language.languageCode)?.content || ''}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChange(language.languageCode, 'content')({ target: { value: data } });
                    }}
                  />
                </div>
              ))}

              <Button type="submit" variant="contained" color="primary">
                Create Page
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default InterestForm;

import Meeting from './Meeting';
import Dashboard from './Dashboard';
import Privacy from './Privacy';

const Layouts = {
  Meeting,
  Dashboard,
 Privacy
};

export default Layouts;

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dropdown from './Dropdown';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


    

export default function OutlinedCard() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    function generateRandomName() {
        const randomString = Math.random().toString(36).substring(7);
        return `name_${randomString}`;
      }

    const HandleNot=()=>{
        let randomName=generateRandomName();
      let randomInterest=["Reading"];
        const interestJson = JSON.stringify(randomInterest);
        dispatch({ type: 'user-email', value: `${randomName}@gmail.com` });
        dispatch({ type: 'user-name', value: `name${randomName}` });
        dispatch({ type: 'setInterest', value: interestJson });
        localStorage.setItem('interest', interestJson)
      
      }
      
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined" sx={{ backgroundColor: '#EBECEE', color: 'black' }}> 
                   <Box sx={{ display: 'flex', justifyContent: 'space-between' , backgroundColor: '#E1E1E1'}}>
                <Typography sx={{ fontSize: 18, fontFamily:'poppins', color: 'black', }} p={1} color="text.secondary" gutterBottom>
                {t('selectYourInterest')}
                </Typography>
                <IconButton >
                    <Typography  sx={{ display: 'flex', alignItems: 'center' , color: 'black'}}>
                       <Button style={{color:'black'}} onClick={HandleNot} >
                    <ClearIcon />

                       </Button>
                    </Typography>
                </IconButton>
            </Box>
            <CardContent>
                <Dropdown />
            </CardContent>
            </Card>
        </Box>
    );
}

import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const getTheme = () => createTheme({
  palette: {
    background: {
      default: 'white',
      paper: '#ffff',
      deep: '#000000',
    },
    primary: {
      main: '#00a7dd',
    },
    secondary: {
      main: '#white',
    },
    text: {
      primary: colors.common.black,
      secondary: '#00a7dd',
    },
    mode: 'dark',
  },
  colors,

  typography,
  dark: true,
});

export default getTheme;

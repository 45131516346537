import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, MenuItem } from '@mui/material';
import { GetPageById, UpdatePage, GetAllLaunguage } from 'src/api'; // Assuming you have an API function to get and update a page by ID
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useParams } from 'react-router-dom';

const EditPage = () => {
  const { id } = useParams();
  const [page, setPage] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetAllLaunguage();
        if (response.status === 200) {
          const languageData = response.data.languages;
          setLanguages(languageData);
          setSelectedLanguage(languageData.length > 0 && languageData[0].languageCode);
        }

        if (id) {
          const interestResponse = await GetPageById(id);
       
          if (interestResponse.status === 200) {

            setPage(interestResponse.data.page.page);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);
  

 
  const handleChange = (languageCode, property) => (event) => {
    setPage((prevInterests) =>
      prevInterests.map((page) =>
        page.languageCode === languageCode ? { ...page, [property]: event.target.value } : page
      )
    );
  };

  const handleLanguageChange = (languageCode) => {
    setSelectedLanguage(languageCode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      page: page,
    };

    let response = await UpdatePage(id, data); 
    if (response.status === 200) {
      navigate('/super_admin/dashboard/page');
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container style={{ margin: '20px 0' }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              select
              label="Language"
              variant="outlined"
              fullWidth
              name="languageCode"
              value={selectedLanguage}
              onChange={(event) => handleLanguageChange(event.target.value)}
            >
              {languages.map((lang) => (
                <MenuItem            
                key={lang.languageCode} value={lang.languageCode}>
                  {lang.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={9}>
            <form onSubmit={handleSubmit}>
              { page.map((language) => (
                <div key={language.languageCode} style={{ display: selectedLanguage === language.languageCode ? 'block' : 'none' }}>
                  <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    name={`title_${language.languageCode}`}
                    value={language.title || ''}
                    onChange={handleChange(language.languageCode, 'title')}
                    disabled={true}
                  />
                  <CKEditor
                    editor={ClassicEditor}
                    disabled={true}
                    data={language.content || ''}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChange(language.languageCode, 'content')({ target: { value: data } });
                    }}
                  />
                </div>
              ))}

            
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default EditPage;

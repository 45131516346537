import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid } from '@mui/material';
import { GetLanguageById, UpdateLanguage } from 'src/api';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';

const EditLanguageForm = () => {
  const [language, setLanguage] = useState({
    value: '',
    languageCode: '',
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const defaultTheme = createTheme();

  useEffect(() => {
    const fetchLanguageDetails = async () => {
      try {
        const response = await GetLanguageById(id);
        if (response.status === 200) {
          const languageData = response.data.language;
          setLanguage(languageData);
        }
      } catch (error) {
        console.error('Error fetching language details:', error);
      }
    };

    fetchLanguageDetails();
  }, [id]);

  const handleChange = (e) => {
    setLanguage({
      ...language,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      value: language.value,
      languageCode: language.languageCode,
    };

    const response = await UpdateLanguage(id, data);
    if (response.status === 200) {
      navigate('/super_admin/dashboard/language');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ margin: '20px 0' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Language Code"
                variant="outlined"
                fullWidth
                name="languageCode"
                value={language.languageCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="value"
                value={language.value}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Update Language
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </ThemeProvider>
  );
};

export default EditLanguageForm;

const initialState = {
  retry: 0,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'setRetry':
        return {
          retry: 1,
        };
      default:
        return state;
    }
  };
  export default reducer;
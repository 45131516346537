import axios from "axios";
import config from "src/config";
const API_ENDPOINT = config.url;

const api = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,

});

const checkUserInterest = async (data) => {
  const response = await api.post('/checkUserInterest', data);

  return response

}
const AdminLogin = async (data) => {
  try {
    const response = await api.post('/admin', data);
    if (response.status === 200) {
      return response
    }
    else {
      return 'Email or Password is not Match'
    }
  } catch (error) {
    console.error('Error checking user admin:', error);
    ;
  }
};
const CreateInterest = async (data) => {
  try {
    const response = await api.post('/create/interest', data);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);
    ;
  }
}
const GetAllInterest = async (data) => {

  try {
    const response = await api.get('/interest', {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': data ? data : 'en'

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);
    ;
  }
};
const GetAllLaunguage = async (data) => {
  try {
    const response = await api.get('/language', {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);
    ;
  }
};
const CreateLaunguage = async (data) => {
  try {
    const response = await api.post('/create/language', data, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);
    ;
  }
};
const GetInterestById = async (data) => {
  try {
    const response = await api.get(`/interest/${data}`, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);
    ;
  }
};
const GetLanguageById = async (data) => {
  try {
    const response = await api.get(`/language/${data}`, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);
    ;
  }
};
const UpdateInterest = async (id, data) => {
  try {
    const response = await api.put(`/interest/update`, {
      id,
      data
    }, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);

  }
};
const UpdateLanguage = async (id, data) => {
  try {
    const response = await api.put(`/language/update`, {
      id,
      data
    }, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);

  }
};
const DeleteLanguage = async (id) => {
  try {
    const response = await api.delete(`/language/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);

  }
};

const DeleteInterest = async (id) => {

  try {
    const response = await api.delete(`/interest/${id}`, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);

  }
};
const CreateReport = async (data) => {

  try {
    const response = await api.post(`/report`, data, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);

  }
}
const GetAllBlock = async () => {

  try {
    const response = await api.get(`/block`, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user interest:', error);

  }
};
const DeleteBlock = async (ip) => {

  try {
    const response = await api.delete(`/block/${ip}`, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);

    return response

  } catch (error) {
    console.error('Error checking user interest:', error);

  }
};

const CreatePage = async (data) => {
  try {
    const response = await api.post('/create/page', data);

    return response;
  } catch (error) {
    console.error('Error checking user page:', error);
    ;
  }
}
const GetAllPage = async (data) => {

  try {
    const response = await api.get('/page', {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': data ? data : 'en'

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user page:', error);
    ;
  }
};
const GetPageById = async (data) => {
  try {
    const response = await api.get(`/page/${data}`, {

      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user page:', error);
    ;
  }
};
const UpdatePage = async (id, data) => {
  try {
    const response = await api.put(`/page/update`, {
      id,
      data
    }, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user page:', error);

  }
};
const DeletePage = async (id) => {

  try {
    const response = await api.delete(`/page/${id}`, {
      headers: {
        'Content-Type': 'application/json',

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user page:', error);

  }
};
const GetPageByName = async (data,en) => {

  try {
    const response = await api.post(`/page/name`, data, {
      headers: {
        'Content-Type': 'application/json',
      'Accept-language':en ? en : 'en'

      }
    },);
    return response;
  } catch (error) {
    console.error('Error checking user page:', error);

  }
};
export {
  checkUserInterest, CreateInterest, CreatePage,
  GetAllPage,
  GetPageById,
  UpdatePage,
  GetPageByName,
  DeletePage, GetAllInterest, AdminLogin, CreateLaunguage, GetAllLaunguage, GetInterestById, UpdateInterest, GetLanguageById, UpdateLanguage, DeleteInterest, DeleteLanguage, CreateReport, GetAllBlock, DeleteBlock
};
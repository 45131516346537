import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { GetAllBlock, DeleteBlock } from 'src/api';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const MyTable = () => {
  const [block, setBlock] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {

    async function Language() {
      let adminToken =sessionStorage.getItem('token');

      if (!adminToken) {
        navigate('/super_admin/login')
        return
      }
      let response = await GetAllBlock();
      if (response.status === 200) {
        setBlock(response.data.block);

      }
    };

    Language()
  }, [refresh]);


  const Handledelete = async (ip) => {
  
    const response = await DeleteBlock(ip)
    if (response.status === 200) {
      setRefresh(!refresh)
    }
  }

  return (
    <>
      <TableContainer style={{ textAlign: 'right' }}>
        <Button sx={{ margin: '10px 0' }} className='text-white' variant="contained" color="primary" onClick={() => navigate('/super_admin/dashboard/block/create')} >Create</Button>
        <Table>

          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>ipaddress</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {block.map((e, i) => (
              <TableRow key={e._id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{e.peer_name}</TableCell>
                <TableCell>{e.peer_ip_address}</TableCell>
                <TableCell>
                  <Button onClick={() => { Handledelete(e.peer_ip_address) }} >Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>

    </>
  );
};

export default MyTable;

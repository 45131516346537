// Import necessary React and Material-UI components
import { Container, Paper, Typography } from '@mui/material';
import React from 'react';
import Footer from 'src/component/Footer';
import Navbar from 'src/component/Navbar';
import Contact from 'src/component/Contact';

const Accept = () => {
  return (
    <>
        <Navbar/>
<Contact/>
    <Container maxWidth="lg" style={{ marginTop: '20px', minHeight:'80vh' }}>
  </Container>
  <Footer/>
  </>

  );
};

// Export PrivacyPolicyPage component
export default Accept;

import { combineReducers } from 'redux';
import chat from './chat';
import drawer from './drawer';
import media from './media';
import meeting from './meeting';
import snack from './snack';
import socket from './socket';
import user from './user';
import interest from './interest';
import language from './language';
import Loading from './Loading';
import showinterest from './ShowInterest';
import dropdown from './dropdown';
import retry from './retry';
import permission from './permission';
import ip from './ip'




const Reducer = combineReducers({
  chat,
  drawer,
  media,
  meeting,
  snack,
  socket,
  user,
  interest,
  language,
  Loading,
  showinterest,
  dropdown,
  retry,
  permission,
  ip
});

export default Reducer;

// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json'; 
import ar from './locales/ar.json'; 
import hi from './locales/hi.json'; 

const resources = {
  en: {
    translation: en,
  },
  hi: {
    translation:hi ,
  },
  ar: {
    translation: ar,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

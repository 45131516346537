import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/system';

import logo from '../assets/loader-img.png';

export default function Loader({ time }) {
  const theme = useTheme();

  return (

    <div>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Container maxWidth="xs">
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent='center' p={1}>
            <img src={logo} alt="Loading Logo" />

            <Typography sx={{
              
              color: '#5A5A5A', fontSize: {
                xs: '24px', // Define font size for extra small screens
                sm: '28px', // Define font size for small screens
                md: '32px', // Define font size for medium screens
                lg: '36px', // Default font size for large screens
              },

              width:'800px'
            }} display="flex"   alignItems="center"  justifyContent='center' p={1}>
              Finding The Best Match 
            </Typography>
            <Typography sx={{ color: '#00a7dd ', fontSize: '22px' }}>
              Retry in {time} sec..

            </Typography>
          </Box>
        </Container>
      </Box>

    </div>
  );
}

import { Navigate } from 'react-router-dom';
import Layouts from './layouts';
import Views from './views';
import Loader from './component/Loader';
import SignIn from './views/Login/Login';
import Language from './views/Dashboard/Language/index';
import CreateLanguage from './views/Dashboard/Language/Create';
import EditLanguage from './views/Dashboard/Language/Edit';
import ViewLanguage from './views/Dashboard/Language/Show';

import Interest from './views/Dashboard/Interest/index';
import Block from './views/Dashboard/Block/index';
import CreateInterest from './views/Dashboard/Interest/Create';
import EditInterest from './views/Dashboard/Interest/Edit';
import ViewInterest from './views/Dashboard/Interest/Show';
import About from './views/Page';
import Contact from './views/Contact';


import Page from './views/Dashboard/Page/index';
import CreatePage from './views/Dashboard/Page/Create';
import EditPage from './views/Dashboard/Page/Edit';
import ViewPage from './views/Dashboard/Page/Show';


const routes = [
  {
    path: '/super_admin',
    element: <Layouts.Dashboard />,
    children: [
      //Interest
      { path: '/super_admin/dashboard/interest', element: <Interest />, exact: true },
      { path: '/super_admin/dashboard/interest/create', element: <CreateInterest />, exact: true },
      { path: '/super_admin/dashboard/interest/edit/:id', element: <EditInterest />, exact: true },
      { path: '/super_admin/dashboard/interest/view/:id', element: <ViewInterest />, exact: true },
      //Page
      { path: '/super_admin/dashboard/page', element: <Page />, exact: true },
      { path: '/super_admin/dashboard/page/create', element: <CreatePage />, exact: true },
      { path: '/super_admin/dashboard/page/edit/:id', element: <EditPage />, exact: true },
      { path: '/super_admin/dashboard/page/view/:id', element: <ViewPage />, exact: true },

      //language
      { path: '/super_admin/dashboard/language', element: <Language />, exact: true },
      { path: '/super_admin/dashboard/language/create', element: <CreateLanguage />, exact: true },
      { path: '/super_admin/dashboard/language/edit/:id', element: <EditLanguage />, exact: true },
      { path: '/super_admin/dashboard/language/view/:id', element: <ViewLanguage />, exact: true },
      //login
      { path: '/super_admin/login', element: <SignIn />, exact: true },
      //block
      { path: '/super_admin/dashboard/block', element: < Block />, exact: true },


    ],
  },
  {
    path: '/',
    element: <Layouts.Meeting />,
    children: [
      { path: '/', element: <Views.Home /> },
      { path: '/block', element: <Views.Block /> },

    ],
  },
  // { path: '/privacy', element: <Privacy /> },
  // { path: '/term/condition/reject', element: <Thank /> },
  // { path: '/term/condition', element: <TermAndCondition /> },
  { path: '/contact', element: <Contact /> },
  { path: '/:name', element: <About /> },
];


export default routes;


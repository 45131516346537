// Import necessary React and Material-UI components
import { Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'src/component/Footer';
import Navbar from 'src/component/Navbar';


const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" style={{ marginTop: '20px', minHeight: '80vh' }}>
        <Paper sx={{ background: 'none', boxShadow: 'none ' }}>
          {/* Title */}
          <Typography variant="h4" gutterBottom>
            {/* {t('privacyTitle')} */}
            Privacy policy OF RIZZ TV

          </Typography>
          <Typography variant='h4'>
            Information Collection
          </Typography>

          <Typography>
            RizzTV does not require users to provide personal information for access.
            Limited technical data, such as IP addresses, may be collected for operational
            purposes
          </Typography>

          <Typography variant='h4'>
            Usage of Information
          </Typography>
          <Typography>
            The collected data is used solely for the functionality and improvement of RizzTV.
            No personal information is stored due to the lack of login or signup features.
          </Typography>
          <Typography variant='h4'>
            Third-Party Links
          </Typography>
          <Typography>
            RizzTV may contain links to third-party websites. This privacy policy does not apply
            to external sites. We are not responsible for any third party links or websites.
          </Typography>
          <Typography variant='h4'>
            Cookies and Tracking
          </Typography>
          <Typography>
            RizzTV may use cookies or similar technologies for basic functionality.
            These technologies do not capture personal information
          </Typography>
          <Typography variant='h4'>
            Security Measures
          </Typography>
          <Typography>
            RizzTV implements reasonable security measures to protect the limited data
            collected.
          </Typography>
          <Typography variant='h4'>Data Retention</Typography>
          <Typography>
            Non-personal data may be retained for operational purposes, but no personal
            information is stored.
          </Typography>
          <Typography variant='h4'>
            Children's Privacy
          </Typography>

          <Typography>
            RizzTV is not intended for users under 18, and no personal information of minors is
            knowingly collected.
          </Typography>
          <Typography variant='h4'>
            Policy Changes
          </Typography>
          <Typography>
            RizzTV reserves the right to update this privacy policy. Users are encouraged to
            check for changes periodically.
          </Typography>
          <Typography variant='h4'>
            Contact Information
          </Typography>
          <Typography>
            For any privacy-related concerns or inquiries, users can contact
            officialrizztv@gmail.com
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </>

  );
};

// Export PrivacyPolicyPage component
export default Privacy;

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Chip, ListItemIcon ,TextField,Autocomplete} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { GetAllInterest } from 'src/api';
import { useEffect, useState } from 'react';
import image from '../../assets/reading.png'
import { useTranslation } from 'react-i18next';

// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';
// import Chip from '@material-ui/core/Chip';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
          
            width: { xs: 400, sm: 600 },
            color: 'white'
    
        },
    },
};


const interestsList = [
    'Art',
    'Baking',
    'Basketball',
    'Blogging',
    'Chess',
    'Community service',
    'Creative activities',
    'Dance',
    'Drawing',
    // 'Foreign languages',
    // 'Gaming',
    // 'Golf',
    // 'Martial arts',
    // 'Music',
    // 'Outdoor activities',
    // 'Photography',
    // 'Playing an instrument',
    // 'Reading',
    // 'Sports',
    // 'Travel',
    // 'Volunteering',
    // 'Woodworking',
    // 'Writing',
    // 'Yoga'
];

function getStyles(name, userInterest, theme) {
    return {
        fontWeight:
            userInterest.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function Dropdown() {
  const [interest, setInterest] = useState([]);
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
    const theme = useTheme();

    const dispatch = useDispatch();

    const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
    const ShowInterest = useSelector((state) => state.showinterest.interest);
    const previousInterest = JSON.parse(ShowInterest);



    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setUserInterest(
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
     
    // };
    const handleTagAdd = (event, value) => {
      if (event.key === 'Enter' && inputValue.trim() !== '') {
        setInterest([...interest, inputValue.trim()]);
        setInputValue('');
      }
    };
    const handleTagDelete = (tagToDelete) => {
      setInterest((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    };
    function generateRandomName() {
        const randomString = Math.random().toString(36).substring(7);
        return `name_${randomString}`;
      }
    

    const InterestSave = () => {
let randomName=generateRandomName();
if(interest.length>0){
    const interestJson = JSON.stringify(interest);
    dispatch({ type: 'user-email', value: `${randomName}@gmail.com` });
    dispatch({ type: 'user-name', value: `name${randomName}` });
    dispatch({ type: 'setInterest', value: interestJson });
    localStorage.setItem('interest', interestJson)
}
}

const HandleNot=()=>{
  let randomName=generateRandomName();
let randomInterest=["Reading"];
  const interestJson = JSON.stringify(randomInterest);
  dispatch({ type: 'user-email', value: `${randomName}@gmail.com` });
  dispatch({ type: 'user-name', value: `name${randomName}` });
  dispatch({ type: 'setInterest', value: interestJson });
  localStorage.setItem('interest', interestJson)

}


useEffect(() => {
  const fetchUserInterest = async () => {

        setInterest(previousInterest ?? []);
       
  };


  fetchUserInterest();
}, [selectedLanguage]);




    return (
        <div>
{/* <FormControl sx={{ width: { xs: 320, sm: 600, md: 800, lg: 1100 }  }} required>
          <InputLabel id="interest-label" sx={{ color: 'black', }}>
            Select your interests
          </InputLabel>
          <Select
            labelId="interest-label"
            required
            sx={{
            // m:1,
              color: 'black',
              border: '1px solid black',
            }}
            multiple
            displayEmpty
            value={userInterest}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                
              }
              return selected.join(', ');
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {interest.map((iName) => (
              <MenuItem
                sx={{  }}
                key={iName._id}
                value={iName.value}
                style={{ ...getStyles(iName.value, userInterest, theme), backgroundColor: 'white', color: 'black' }}
              >
                <ListItemIcon>
                  <img
                    src={image}
                    alt="Your Logo"
                    style={{ width: 24, height: 24 }}
                  />
            
                
              
                </ListItemIcon>
                {iName.value}
              </MenuItem>
            ))}
          </Select>
      
          <Typography
            sx={{ font: '15px', display: 'flex', fontFamily: 'poppins', alignItems: 'center', justifyContent: 'center', padding: '10px 25px' }}
          >
            Please select your interests.
          </Typography>
        </FormControl> */}

<div style={{minWidth:'50vw',border:'1px solid #d4c8c8',borderRadius:'7px' }}>
      <Autocomplete
        multiple
        freeSolo
        id="interest-outlined"
        options={interest}
        getOptionLabel={(option) => option}
        value={interest}
        onChange={(event, newValue) => setInterest(newValue)}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderTags={(value, getTagProps) =>
          value.map((tag, index) => (
            <Chip
            style={{background:'#00a7dd',color:'white'}}
              key={index}
              label={tag}
              onDelete={() => handleTagDelete(tag)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t('typeAndPressEnter')}
            onKeyPress={handleTagAdd}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
      />
    </div>
        <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black',paddingTop:'30px' }}>
          <Button variant="contained" onClick={HandleNot} sx={{ backgroundColor: '#00a7dd', width: '100px', color: 'white', margin: '0 10px' }}>
          {t('notNow')}
          </Button>
          <Button onClick={InterestSave} variant="contained" sx={{ backgroundColor: '#00a7dd', color: 'white', width: '100px' }}>
          {t('save')}
          </Button>
        </Typography>
      </div>
      
    );
}

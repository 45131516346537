import {
  Box, Button, Card, Container, TextField, Typography, useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OpenInNew } from '@mui/icons-material';
import { json, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import Common from 'src/common';
import config from 'src/config';
import LocalVideo from 'src/views/Join/LocalVideo';
import MicrophoneToggle from 'src/views/Join/MicrophoneToggle';
import CameraToggle from 'src/views/Join/CameraToggle';
import getLocalVideo from 'src/actions/media/getLocalVideo';
import NameInput from 'src/views/Join/NameInput';
import EmailInput from 'src/views/Join/EmailInput';
import JoinButton from 'src/views/Join/JoinButton';
import info from '../version.json';
import logo from 'src/views/HomeOld/logo.png';
import randomWords from 'random-words';
import Utils from '../utils';
import Actions from '../actions';
import Loading from './Loader';
import { checkUserInterest } from 'src/api';
import Meeting from './Meeting';




function Join() {
  const [interestData, setInterestData] = useState([])
  const [time, setTime] = useState(5);

  const navigate = useNavigate();

  const retry = useSelector((state) => state.retry.retry);


  const key = useSelector((state) => state.meeting.key);
  const name = useSelector((state) => state.user.name);
  const uuid = useSelector((state) => state.media.uuid);
  const globalloading = useSelector((state) => state.Loading.loading);
  const useinterest = useSelector((state) => state.interest.interest);
  const showInterest = useSelector((state) => state.showinterest.interest);
  let apiCalledRef = useRef(false); 

  const ip = useSelector((state) => state.ip.ip);

  // const [loading, setLoading] = useState(true);

  let loading=useRef(true)



  function generateRandomName() {
    const randomString = Math.random().toString(36).substring(7);
    return `name_${randomString}`;
  }

  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket.id);

  if (socket) {
    dispatch(Actions.IO.joinRoom(key));
  }

  // console.log(loading)
  useEffect(() => {

    const fetchUserInterest = async () => {

      try {  
        const parse = JSON.parse(useinterest);

        const response = await checkUserInterest({
          interest: parse,
          name: name,
          uuid: uuid,
          ip:ip
        });

        if (response.status === 200) {
        
          let key = await response.data.session.session_code;
          let randomName = generateRandomName();
          dispatch({ type: 'user-email', value: `${randomName}@gmail.com` });
          dispatch({ type: 'user-name', value: `name${randomName}` });
          dispatch({ type: 'meeting-key', value: key });
          dispatch({ type: 'join', name: `name${randomName}`, email: `${randomName}@gmail.com` });
          // dispatch({ type: 'SET_LOADING', value: false });
          loading.current=false        
        }

        // if (response.status==307){

        //   navigate('/block')
        // }

      } catch (error) {


if(error.response.status==307){
  navigate('/block')
}
       
      }
   
  }
    const initiateRetries = () => {
    
      let countdown =5;
      const countdownInterval = setInterval(() => {
        countdown--;
        setTime(countdown);

        if (countdown === 0) {
          clearInterval(countdownInterval);
        if(useinterest){
 
          fetchUserInterest();
        }
         
        }
      }, 1000);
    };
 
    if (!key || Utils.isEmpty(key)  && useinterest ) {
      initiateRetries();
    }
  }, []);



  return (
    <>
      {loading.current ? (
        <>
          <Loading time={time} />

        </>
      ) : (
        <Meeting />
      )}
    </>
  );
}
export default Join;

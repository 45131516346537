import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetPageByName } from 'src/api';
import Footer from 'src/component/Footer';
import Navbar from 'src/component/Navbar';
import { Container, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const Accept = () => {
  const [Page, setPage] = useState([]);
  let { name } = useParams();
  const selectedLanguage=useSelector((e)=>e.language.selectedLanguage);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  useEffect(() => {
    async function GetPage() {
      try {
        const data={
          name:capitalizeFirstLetter(name)
        }
        const response = await GetPageByName(data,selectedLanguage);
        if (response.status === 200) {

   
          setPage(response.data.pages);
        } else {
          console.error('Error fetching page:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching page:', error.message);
      }
    }
    GetPage();
  }, [name ,selectedLanguage]);

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" style={{ minHeight: '80vh', marginTop: '20px' }}>
        <Paper sx={{ background: 'none', boxShadow: 'none ' }}>
        {Page.map((e, i) => (
          <div key={i} dangerouslySetInnerHTML={{ __html: ` ${e.title} <br />${e.content}` }} />
          ))}
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Accept;
